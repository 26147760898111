<template>
  <div class="diylist" :style="{background: comObj.essentialKey.background}">
    <div class="TabControl" v-if="comObj.tabArray.length > 0">
      <!-- 选项卡 -->
      <tabs v-model="active" @click="changeTab">
        <tab :title="item.title" v-for="(item, index) in comObj.tabArray" :key="index"></tab>
      </tabs>
    </div>
    <div v-if="comObj.listStyle == 0" class="sortFlex">
      <div
        class="listcon acrossList"
        v-for="(items, index) in listdata"
        :key="index"
        @click="goDetail(items.id)"
      >
        <div class="lfts">
          <img :src="items.image[0].url" alt />
        </div>

        <div class="rgts">
          <!--列表文本组件-->
          <div class="rowTitle">
            <span
              style="line-height:20px"
              :style="{'color' : comObj.fontColor.titleColor}"
            >{{items.name}}</span>
          </div>

          <div class="subheading">
            <span>自定义商品副标题，一行显示，多余...表示多余...表示</span>
          </div>

          <div class="listAct">
            <div class="ActivityLabel">
              <tag type="danger" plain size="mini" effect="plain" style="margin-right:10px">满300-30</tag>
              <tag type="danger" plain size="mini" effect="plain">新品</tag>
            </div>
          </div>

          <!--列表旧价格组件   -->
          <div class="amendPrice editlocal">
            <span :style="{'color' : comObj.fontColor.priceColor}">￥{{items.price}}元</span>
            <span
              class="evaluate"
              :style="{'color' : comObj.fontColor.evalColor}"
            >{{items.comments_count}}评价</span>
          </div>
        </div>
      </div>
    </div>
<!-- 样式二 -->
    <div v-if="comObj.listStyle == 1" class="sortFlex">
      <div
        class="listcon subfield"
        v-for="(items, index) in listdata"
        :key="'info-' + index"
        @click="goDetail(items.id)"
      >
        <!-- 列表图片组件 -->
        <div class="twoImgStyle">
          <img :src="items.image[0].url" alt />
        </div>
        <!-- 标题 -->
        <div class="amendTitle">
          <span
            style="font-size:16px;"
            :style="{'color' : comObj.fontColor.titleColor}"
          >{{items.name}}</span>
        </div>
        <!--列表按钮组件-->
        <div class="amendActivity">
          <div class="ActivityLabel">
            <tag type="danger" plain size="mini" effect="plain" style="margin-right:10px">满300-30</tag>
            <tag type="danger" plain size="mini" effect="plain">新品</tag>
          </div>
        </div>

        <!--列表价格组件-->
        <div class="amendPrice">
          <span :style="{'color' : comObj.fontColor.priceColor}">￥{{items.price}}元</span>
          <span
            class="evaluate"
            :style="{'color' : comObj.fontColor.evalColor}"
          >{{items.comments_count}}评价</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { App_ID } from "@/api/publicHeader";
import axios from "axios";
import { Tab, Tabs, Tag } from "vant";
// import { Container, Draggable } from "vue-smooth-dnd";
export default {
  props: ["childCon", "comObj", "nowComIdx"],
  components: {
    Tab,
    Tabs,
    Tag
    // DiyImg: () => import("../diycomponents/DiyImg")
  },
  computed: {},
  data() {
    return {
      App_ID,
      page: 1,
      listdata: [],
      selectStyle: false,
      active: 0,
      classId: "",
      limit: ""
    }; 
  },
  mounted() {
    this.getClassId();
    console.log(this.comObj);
  },
  methods: {
    changeTab(name, title) {
      // 切换分类
      console.log(name, title);
      this.classId = this.comObj.tabArray[name].id
      this.getList();
    },
    getClassId() {
      if (this.comObj.tabArray.length > 0) {
        this.classId = this.comObj.tabArray[0].id
      } else {
        this.classId = "";
      }
      this.limit = this.comObj.essentialKey.listNum;
      this.getList();
    },
    goDetail(id) {
      this.$router.push({
        path: "/ShopDetail",
        query: {
          goods_id: id
        }
      });
    },
    getList() {
      axios({
        method: 'post',
        url: 'https://www.58zltc.com/goods/user.goods/index',
        header: "application/x-www-form-urlencoded",
        data: {
          app_id: this.App_ID,
          page: this.page,
          limit: this.limit,
          listNum: this.classId
        }
      }).then(res => {
        console.log(res);
        this.listdata = res.data.data;
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin site($wid, $hig, $top, $left) {
  width: $wid;
  height: $hig;
  position: absolute;
  top: $top;
  left: $left;
}
.sortFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.diylist {
  display: flex;
  flex-wrap: wrap;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  .TabControl {
    width: 100%;
  }
}
.lfts {
  width: 130px;
  height: 130px;
  padding: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
.rgts {
  flex: 1;
  padding: 10px 0;
}
.acrossList {
  width: 100%;
  min-height: 130px;
  display: flex;
  flex-direction: row;
}
.listcon {
  width: 100%;
  // height: 100%;
  position: relative;
  // background: #ffffff;
  .list-img {
    @include site(50px, 50px, 10px, 10px);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.subfield {
  width: 50% !important;
  height: 265px !important;
  // overflow: hidden;
}
.listborder {
  border: 1px solid #dfdfdf;
}

.listAct {
  width: 230px;
  height: 30px;
  z-index: auto;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ff8000;
}
.subheading {
  z-index: auto;
  font-size: 12px;
  color: #999999;
  text-decoration: none;
  width: 230px;
  line-height: 20px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rowTitle {
  z-index: auto;
  font-size: 16px;
  color: rgb(51, 51, 51);
  text-decoration: none;
  width: 230px;
  height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.amendTitle {
  margin-bottom: 5px;
  padding: 0 12px;
  width: 100%;
  color: #4c4c4c !important;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.amendActivity {
  padding: 0 12px;
  width: 100%;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 5px;
}
.amendPrice {
  padding: 0 12px;
  z-index: auto;
  width: 100%;
  height: 20px;
  font-size: 16px;
  line-height: 20px;
  text-align: left !important;
}
.evaluate {
  margin-left: 10px;
  font-size: 12px;
  color: #989797;
}
.editlocal {
  width: 230px;
  padding: 0;
}
.oneImgStyle {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 110px;
  height: 110px;
}
.twoImgStyle {
  width: 100%;
  padding: 5px;
  height: 183px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss">
.vdr.active:before {
  outline: 0;
}
</style>
